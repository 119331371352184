import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
// @material-ui iconsStyle
import AttachFileIcon from "@material-ui/icons/AttachFile";
// @material-ui/core components
import { message } from "antd";

// import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// core component
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import { validations } from "validators/messages";

//Styles
import { primaryColor, infoColor } from "assets/jss/material-dashboard-pro-react.js";
import useStyles from "../../assets/jss/material-dashboard-pro-react/views/common";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const camposObligatorios = ["numeroMatricula", "proyectoId"];

const themeInputInfo = createTheme({
  palette: {
      primary: {
          main: infoColor[0],
          light: infoColor[0],
          dark: infoColor[0],
      },
      secondary: {
          main: primaryColor[0],
          light: primaryColor[0],
          dark: primaryColor[0],
      }
  },
});

const FormItem = (props) => {
  const { title, createItem, loading, proyectos, onEdit, setOnEdit, valuesEditInmbuebles, setValuesEditInmbuebles, tiposInmuebles } = props;
  const [valuesState, setValuesState] = useState();
  const [errors, setErrors] = useState({
    numeroMatricula: null,
    numeroPredial: null,
    numeroCatastral: null,
    numeroInmueble: null,
    linderos: null,
    area: null,
    direccion: null,
    coeficiente: null,
    avaluo: null,
    vCancelacionHipoteca: null
  });

  let ProyectoRef = useRef(null);
  let InmuebleRef = useRef(null);

  const classes = useStyles();

  const validateInput = (input, name) => {
    if (camposObligatorios.includes(name)) {
      if (input && input.toString().trim().length > 0) {
        setErrors((state) => ({ ...state, [name]: null }));
      } else {
        setErrors((state) => ({ ...state, [name]: "Este campo es requerido" }));
      }
    }
  };

  const updateInput = (e) => {
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setValuesEditInmbuebles((state) => ({ ...state, [inputName]: inputValue }));
    // Solo validar obligatorios
    if (camposObligatorios.includes(inputName)) {
      validateInput(inputValue, inputName);
    }
  };

  const updateValues = (e) => {
    const { name, value } = e.target;
    if (name === "proyectoId") {
      // Si necesitas obtener información extra del proyecto, puedes usar getProyecto.
      // Aquí simplemente asignamos el valor seleccionado.
      setValuesEditInmbuebles((state) => ({
        ...state,
        proyectoId: value,
      }));
    } else if (name === "id_tipo_inmueble") {
      // De igual forma, para el tipo de inmueble
      setValuesEditInmbuebles((state) => ({
        ...state,
        id_tipo_inmueble: value,
      }));
    } else {
      // Para otros campos se asigna de forma directa.
      setValuesEditInmbuebles((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const clean = (e) => {
    setValuesEditInmbuebles((state) => ({
      numeroMatricula: "",
      numeroPredial: "",
      numeroCatastral: "",
      numeroInmueble: "",
      linderos: "",
      area: "",
      direccion: "",
      coeficiente: "",
      avaluo:"",
      id_tipo_inmueble: null,
      proyectoId: null,
      vCancelacionHipoteca:""
    }));

    setOnEdit(false);
  }
  
  
  const updateValuesState = (input, error) => {
    setValuesState((state) => ({ ...state, [input]: error }));
  };
  const add = (e) => {
    e.preventDefault();
    validateInput(valuesEditInmbuebles.numeroMatricula, "numeroMatricula");
    validateInput(valuesEditInmbuebles.numeroPredial, "numero");
    validateInput(valuesEditInmbuebles.numeroCatastral, "numero");
    validateInput(valuesEditInmbuebles.linderos, "linderos");
    validateInput(valuesEditInmbuebles.area, "area");
    validateInput(valuesEditInmbuebles.direccion, "direccion");
    validateInput(valuesEditInmbuebles.coeficiente, "coeficiente");
    validateInput(valuesEditInmbuebles.avaluo, "avaluo");
    validateInput(valuesEditInmbuebles.numeroInmueble, "numeroInmueble");
    validateInput(valuesEditInmbuebles.vCancelacionHipoteca, "vCancelacionHipoteca");
     // Campos obligatorios
    const camposObligatorios = ["numeroMatricula", "proyectoId"];
    const faltantes = camposObligatorios.filter(
      (campo) => !valuesEditInmbuebles[campo] || valuesEditInmbuebles[campo] === ""
    );

    if (faltantes.length > 0) {
      message.error("Revise la informacion requerida", 1);
      return;
    }
    if(onEdit){
      // funcion editar
    }
      createItem(valuesEditInmbuebles, setValuesEditInmbuebles);
  };

  return (
    <Card>
      <CardHeader color="primary" text>
        <CardText className={classes.cardText} color="primary">
          <h4 className={classes.colorWhite}>{title}</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <ThemeProvider theme={themeInputInfo}>
          <form onSubmit={add}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              {/* Campo 1: Número Matrícula Inmobiliaria (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    required
                    name="numeroMatricula"
                    type="text"
                    placeholder="NÚMERO MATRÍCULA INMOBILIARIA *"
                    value={valuesEditInmbuebles.numeroMatricula}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroMatricula}
                    helperText={errors.numeroMatricula}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              
              {/* Campo 2: Número Predial (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="numeroPredial"
                    type="text"
                    placeholder="NÚMERO PREDIAL"
                    value={valuesEditInmbuebles.numeroPredial}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroPredial}
                    helperText={errors.numeroPredial}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 4: Número Catastral (string) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="numeroCatastral"
                    type="text"
                    placeholder="NÚMERO CATASTRAL"
                    value={valuesEditInmbuebles.numeroCatastral}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroCatastral}
                    helperText={errors.numeroCatastral}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 5: Linderos (text) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="linderos"
                    type="text"
                    placeholder="LINDEROS"
                    value={valuesEditInmbuebles.linderos}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.linderos}
                    helperText={errors.linderos}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 6: Area (text) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="area"
                    type="text"
                    placeholder="AREA"
                    value={valuesEditInmbuebles.area}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.area}
                    helperText={errors.area}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              
               {/* Campo 7: direccion (text) */}
               <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="direccion"
                    type="text"
                    placeholder="DIRECCION"
                    value={valuesEditInmbuebles.direccion}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.direccion}
                    helperText={errors.direccion}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 8: Coeficiente (float) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="coeficiente"
                    type="number"
                    step="0.01"
                    placeholder="COEFICIENTE"
                    value={valuesEditInmbuebles.coeficiente}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.coeficiente}
                    helperText={errors.coeficiente}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  
              {/* Campo 9: Avalúo Catastral Prorrata (number with increment/decrement) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="avaluo"
                    type="number"
                    step="1"
                    placeholder="AVALÚO CATASTRAL PRORRATA"
                    value={valuesEditInmbuebles.avaluo}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.avaluo}
                    helperText={errors.avaluo}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Campo 10: vCancelacionHipoteca (input) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="vCancelacionHipoteca"
                    type="number"
                    step="0.01"
                    placeholder="V. CANCELACION HIPOTECA"
                    value={valuesEditInmbuebles.vCancelacionHipoteca}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.vCancelacionHipoteca}
                    helperText={errors.vCancelacionHipoteca}
                    fullWidth
                  />
                </FormControl>
              </Grid>
  

              {/* Campo 11: tipo inmueble (Select) */}
              <GridItem xs={12} sm={6} md={4} lg={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    value={valuesEditInmbuebles.proyectoId || ""}
                    label="Proyecto"
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    displayEmpty
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        updateValuesState("proyectoId", "success");
                      } else {
                        updateValuesState("proyectoId", "error");
                      }
                      updateValues(event);
                    }}
                    inputProps={{
                      name: "proyectoId",
                      inputRef: ProyectoRef,
                    }}
                  >
                    <MenuItem
                      disabled
                      value=""
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Seleccione Proyecto
                    </MenuItem>
                    {proyectos?.map(({ codigo, proyecto }) => (
                      <MenuItem
                        key={codigo}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={codigo}
                      >
                        {proyecto}
                      </MenuItem>
                    ))}
                  </Select>

                  </FormControl>
              </GridItem>
              
              {/* Campo 12: Tipo de Inmueble) */}
              <GridItem xs={12} sm={6} md={4} lg={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                        value={valuesEditInmbuebles.id_tipo_inmueble || ""}
                        label="Tipo de inmueble"
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        displayEmpty
                        onChange={(event) => {
                          if (event.target.value !== "") {
                            updateValuesState("id_tipo_inmueble", "success");
                          } else {
                            updateValuesState("id_tipo_inmueble", "error");
                          }
                          updateValues(event);
                        }}
                        inputProps={{
                          name: "id_tipo_inmueble",
                          inputRef: InmuebleRef,
                        }}
                      >
                        <MenuItem
                          disabled
                          value=""
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          Tipo de inmueble
                        </MenuItem>
                        {tiposInmuebles?.map(({ id, name }) => (
                          <MenuItem
                            key={id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={id}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>

                  </FormControl>
              </GridItem>

              {/* Campo 12: Número de inmueble) */}
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <TextField
                    // required
                    name="numeroInmueble"
                    type="string"
                    placeholder="NÚMERO DE INMUEBLE"
                    value={valuesEditInmbuebles.numeroInmueble}
                    onChange={updateInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        style: {
                          fontSize: "0.8rem",
                          color: "#000000d9",
                          opacity: 1,
                        },
                      },
                    }}
                    error={!!errors.numeroInmueble}
                    helperText={errors.numeroInmueble}
                    fullWidth
                  />
                </FormControl>
              </Grid>

              {/* Boton Añadir */}
            </Grid>
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Button
                type="submit"
                disabled={loading}
                color="rose"
                size="sm"
                // fullWidth
              >
                {onEdit ? "Editar" : "Añadir"}
              </Button>
              {onEdit ? <Button
                type="button"
                disabled={loading}
                color="rose"
                size="sm"
                onClick={()=>clean()}
                // fullWidth
              >
                Cancelar
              </Button> : <></>}
              
            </Grid>
          </form>
        </ThemeProvider>
      </CardBody>
    </Card>
  );
  
  
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createItem: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FormItem;

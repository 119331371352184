import React from "react";
import { Popconfirm, Input, Space, Tooltip, Button as ButtonAnt } from "antd";
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import GetViewIcon from "@material-ui/icons/Visibility";
import {Drawer} from '@material-ui/core/';
import { SearchOutlined } from "@ant-design/icons";

const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const getColumnSearchProps = (dataIndex, searchInput) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder="Busqueda"
        value={selectedKeys[0] || ""}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(confirm)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="sm"
          style={{ width: 90, backgroundColor: "#40a9ff" }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="sm"
          style={{ width: 90 }}
        >
          Limpiar
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
});

function columns({delItem, searchInput, classes, setValues, editItem, drawerState, toggleDrawer, list}) {
  return [
    {
      title: "Matrícula",
      dataIndex: "numeroMatricula",
      key: "numeroMatricula",
      width: "30%",
      sorter: (a, b) => (a.numeroMatricula ? a.numeroMatricula.localeCompare(b.numeroMatricula) : 0),
      ...getColumnSearchProps("numeroMatricula", searchInput),
    },
    {
      title: "Proyecto",
      dataIndex: "proyectoName",
      key: "proyectoName",
      width: "30%",
      sorter: (a, b) => (a.proyectoName ? a.proyectoName.localeCompare(b.proyectoName) : 0),
      ...getColumnSearchProps("proyectoName", searchInput),
    },
    {
      title: "Número Predial",
      dataIndex: "numeroPredial",
      key: "numeroPredial",
      width: "20%",
      sorter: (a, b) => (a.numeroPredial ? a.numeroPredial.localeCompare(b.numeroPredial) : 0),
      ...getColumnSearchProps("numeroPredial", searchInput),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <div key={record.id || record.numeroMatricula}>
          <Tooltip title={"Ver"}>
              <Button
                color="info"
                className={classes.actionButton}
                onClick={() => toggleDrawer(record.id, "right", true)}
              >
                <GetViewIcon className={classes.icon} />
              </Button>
            </Tooltip>
            <Drawer
              anchor={"right"}
              open={drawerState[record.id]?.right || false}
              onClose={() => toggleDrawer(record.id, "right", false)}
            >
              {list("right", record)}
            </Drawer>
          <Tooltip title="Editar">
            <Button
              color="success"
              className={classes.actionButton}
              onClick={() => {
                editItem(record, setValues);
              }}
            >
              <Edit className={classes.icon} />
            </Button>
          </Tooltip>
          <Popconfirm title="Seguro deseas eliminar?" onConfirm={() => delItem(record.id)}>
            <Button color="danger" className={classes.actionButton}>
              <Close className={classes.icon} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];
}

export { columns };
import React, { useEffect, useRef, useState } from "react";
import { Table, Form, Spin, Alert } from "antd";
import "antd/dist/antd.css";
//material ui
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import {Box, List, ListItem,Typography} from '@material-ui/core/';
import Button from "components/CustomButtons/Button.js";
//Local
import EditableCell from "../../components/Custom/EditableCellInmueble";
import useTable from "../../hooks/useTableInmueble";
import { columns } from "../../utils/columnInmuebles";
import FormItem from "../../components/Custom/FormInmuebles";

//Styles
import useStyles from "../../assets/jss/material-dashboard-pro-react/views/common";
//Constants
const title = "Inmuebles";
const name = "Inmueble";
const key = "inmuebles";

export default function EditableTable() {
  const classes = useStyles();
  const [loadPermit, setLoadPermit] = useState("hide");
  const [modal, setModal] = useState({
    visible: false,
    id: "",
  });
  const [drawerState, setDrawerState] = useState({});
  const [valuesEditInmbuebles, setValuesEditInmbuebles] = useState({
    numeroMatricula: "",
    numeroPredial: "",
    numeroCatastral: "",
    numeroInmueble: "",
    linderos: "",
    area: "",
    direccion: "",
    coeficiente: "",
    avaluo:"",
    id_tipo_inmueble: null,
    proyectoId: null,
    vCancelacionHipoteca: ""
  });
  const searchInput = useRef();
  const {
    formEdit,
    formCreate,
    data,
    loading,
    onEdit,
    onDelete,
    updateOnEdit,
    createItem,
    editItem,
    delItem,
    save,
    isEditing,
    cancel,
    onFinishFailed,
    updateNameItem,
    getDocument,
    proyectos,
    tiposInmuebles,
    setTiposInmuebles,
    setOnEdit
  } = useTable({ key, setValuesEditInmbuebles  });

  useEffect(() => {
    updateNameItem(name);
  }, []);

  const setStatePermit = (val) => {
    setLoadPermit(val);
  };
  const toggleDrawer = (id, anchor, open) => {
    setDrawerState((prevState) => {
      if (prevState[id]?.[anchor] === open) {
        return prevState;
      }
      return {
        ...prevState,
        [id]: {
          ...prevState[id],
          [anchor]: open,
        },
      };
    });
  };
  const showModal = (record) => {
    setModal((mod) => ({
      ...mod,
      visible: true,
      rolName: record.name,
      id: record.id,
    }));
  };

  const handleCancel = () => {
    setModal((mod) => ({ ...mod, visible: false }));
  };

  const list = (anchor,data) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
    <div>
      <Typography
        variant="h5"
        component="div"
        gutterBottom
      >
        Datos del Inmueble:
      </Typography>
      <List>
        {Object.entries(data).map(([key, value]) => (
          <ListItem key={key}>
            <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
          </ListItem>
        ))}
      </List>
    </div>
    </Box>
  );

  return (
    <>
	<FormItem
        name={name}
        title={title}
        maxName={30}
        maxCode={5}
        formCreate={formCreate}
        createItem={createItem}
        onFinishFailed={onFinishFailed}
        loading={loading}
        proyectos={proyectos}
        tiposInmuebles={tiposInmuebles}
        onEdit={onEdit}
        setOnEdit={setOnEdit}
        valuesEditInmbuebles={valuesEditInmbuebles}
        setValuesEditInmbuebles={setValuesEditInmbuebles}
		  // form={formEdit} {...props}
	/>
  
      <br />
<Form form={formEdit} component={false}>
  <Table
    className={classes.table}
    components={{
      body: {
        cell: (props) => <EditableCell form={formEdit} {...props} />,
      },
    }}
    size="small"
    bordered
    scroll={{ x: 500 }}
    dataSource={data}
    rowKey={(record) => record.id || record.numeroMatricula}
    columns={columns({
      cancel,
      isEditing,
      updateOnEdit,
      save,
      setValues: setValuesEditInmbuebles,
      delItem,
      list,
      editItem,
      onEdit,
      onDelete,
      name,
      searchInput,
      classes,
      toggleDrawer,
      drawerState
    })}
    loading={loading}
    rowClassName="editable-row"
    pagination={{
      onChange: cancel,
      pageSize: 10,
    }}
  />
</Form>


      <Dialog
        open={modal.visible}
        keepMounted
        onClose={handleCancel}
        aria-labelledby="permits-title"
        aria-describedby="permits-description"
      >
        <DialogTitle id="permits-title">
          <div className={classes.titleModal}>
            <VerifiedUserIcon />
            <p className={classes.text}> Permisos para {modal.rolName}</p>
          </div>
        </DialogTitle>
        <DialogActions>
          <Spin style={{ marginRight: 100 }} spinning={loadPermit === "load"}>
            {loadPermit !== "hide" && loadPermit !== "load" ? (
              <Alert
                message={
                  loadPermit === "error"
                    ? "No se pudo guardar"
                    : "Guardado correctamente"
                }
                type={loadPermit}
              />
            ) : (
              false
            )}
          </Spin>
          <Button onClick={handleCancel} color="danger" simple>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

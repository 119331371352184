import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, message, Button,Popconfirm } from "antd";
import { EXTERNAL_API_PATHS } from "../utils/constants";

const useTableInmueble = ({ key, setValuesEditInmbuebles }) => {
  const [formEdit] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [nameItem, setNameItem] = useState("");
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [proyectos, setProyectos] = useState([]);
  const [tiposInmuebles, setTiposInmuebles] = useState([]);
  const [onEdit, setOnEdit] = useState(false);

  // Fetch data for the table
  useEffect(() => {
    setLoading(true);
    axios
      .get(EXTERNAL_API_PATHS[key])
      .then((res) => {
        const formattedData = res.data.map((item) => ({
          id: item.id,
          numeroMatricula: item.matricula,
          numeroPredial: item.numero_predial,
          numeroCatastral: item.numero_catastral,
          numeroInmueble: item.numero_inmueble,
          linderos: item.linderos,
          area: item.area,
          direccion: item.direccion,
          avaluo: item.avaluo,
          coeficiente: item.coeficiente_ph,
          proyectoId: item.proyecto?.codigo,
          proyectoName: item.proyecto?.proyecto,
          id_tipo_inmueble: item.id_tipo_inmueble,
          vCancelacionHipoteca: item.vCancelacionHipoteca
        }));
        setData(formattedData);
      })
      .finally(() => setLoading(false));
  }, [key]);

  // Fetch related projects
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["proyecto2"]).then((res) => {
      setProyectos(res.data);
    });
  }, []);
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["tipoInmueble"]).then((res) => {
      setTiposInmuebles(res.data);
    });
  }, []);

  // Create new item
  const createItem = (values) => {
    const formData = new FormData();
    formData.append("matricula", values.numeroMatricula);
    formData.append("numero_predial", values.numeroPredial);
    formData.append("numero_catastral", values.numeroCatastral);
    formData.append("numero_inmueble", values.numeroInmueble);
    formData.append("linderos", values.linderos);
    formData.append("area", values.area);
    formData.append("direccion", values.direccion);
    formData.append("avaluo", values.avaluo);
    formData.append("coeficiente_ph", values.coeficiente);
    formData.append("id_proyecto", values.proyectoId);
    formData.append("id_tipo_inmueble", values.id_tipo_inmueble);
    formData.append("vCancelacionHipoteca", values.vCancelacionHipoteca);
  
    if (onEdit && editingKey) {
      message.loading("Guardando cambios...");
      axios
      // aAca iria el api de editar
        .post(`${EXTERNAL_API_PATHS[key]}/${editingKey}?_method=PUT`, formData)
        .then(() => {
          setData((prevData) =>
            prevData.map((item) =>
              item.id === editingKey
                ? {
                    ...item,
                    numeroMatricula: values.numeroMatricula,
                    numeroPredial: values.numeroPredial,
                    numeroCatastral: values.numeroCatastral,
                    numeroInmueble: values.numeroInmueble,
                    linderos: values.linderos,
                    area: values.area,
                    direccion: values.direccion,
                    avaluo: values.avaluo,
                    coeficiente: values.coeficiente,
                    proyectoId: values.proyectoId,
                    proyectoName: proyectos.find(p => p.codigo === values.proyectoId)?.proyecto ?? '',
                    id_tipo_inmueble: tiposInmuebles.find(p => p.id === values.id_tipo_inmueble)?.name ?? '',
                    vCancelacionHipoteca: values.vCancelacionHipoteca                  
                  }
                : item
            )
          );

          message.success("Registro actualizado con éxito");
          cancel();
        })
        .catch(() => {
          message.error("No se pudo actualizar el registro");
        });
    } else {
      // Crear un nuevo registro si no estamos editando
      message.loading("Guardando...");
      axios
        .post(EXTERNAL_API_PATHS[key], formData)
        .then((res) => {
          if (res.status === 201) {
            setData((prevData) => [
              ...prevData,
              {
                id: res.data.id,
                numeroMatricula: res.data.matricula,
                numeroPredial: res.data.numero_predial,
                numeroCatastral: res.data.numero_catastral,
                numeroInmueble: res.data.numero_inmueble,
                linderos: res.data.linderos,
                area: res.data.area,
                direccion: res.data.direccion,
                avaluo: res.data.avaluo,
                coeficiente: res.data.coeficiente_ph,
                proyectoId: res.data.proyecto?.codigo,
                proyectoName: res.data.proyecto?.proyecto,
                id_tipo_inmueble: res.data.id_tipo_inmueble,
                vCancelacionHipoteca: res.data.vCancelacionHipoteca
              },
            ]);
            message.success(`${nameItem} creado con éxito`);
            cancel();
          } else {
            message.error(`No se pudo crear ${nameItem}`);
          }
        })
        .catch(() => {
          message.error(`No se pudo crear ${nameItem}`);
        });
    }
  };

    // Edit item
    const editItem = (record, setValues) => {
      setOnEdit(true);
      console.log(record)
      setValues({
        numeroMatricula: record.numeroMatricula,
        numeroPredial: record.numeroPredial ?? '',
        numeroCatastral: record.numeroCatastral ?? '',
        numeroInmueble: record.numeroInmueble ?? '',
        linderos: record.linderos ?? '',
        area: record.area ?? '',
        direccion: record.direccion ?? '',
        avaluo: record.avaluo ?? '',
        coeficiente: record.coeficiente ?? '',
        proyectoId: record.proyectoId ?? '',
        id_tipo_inmueble: record.id_tipo_inmueble ?? '',
        vCancelacionHipoteca: record.vCancelacionHipoteca ?? ''
      });
      setEditingKey(record.id);

      // updateNameItem("Editar");
    };

    const save = async (id) => {
      try {
        const row = await formEdit.validateFields();
        const index = data.findIndex((item) => item.id === id);
    
        if (index > -1) {
          const updatedItem = {
            ...data[index],
            numeroMatricula: row.numeroMatricula,
            numeroPredial: row.numeroPredial,
            numeroCatastral: row.numeroCatastral,
            numeroInmueble: row.numeroInmueble,
            linderos: row.linderos,
            area: row.area,
            direccion: row.direccion,
            avaluo: row.avaluo,
            coeficiente: row.coeficiente,
            proyectoId: row.proyectoId,
            vCancelacionHipoteca: row.vCancelacionHipoteca
          };
    
          message.loading("Guardando cambios...");
          await axios.put(`${EXTERNAL_API_PATHS[key]}/${id}`, updatedItem);
    
          setData((prevData) => {
            const newData = [...prevData];
            newData[index] = updatedItem;
            return newData;
          });
    
          setEditingKey("");
          message.success("Registro editado con éxito");
        }
      } catch (error) {
        message.error("Error al guardar los cambios");
      }
    };

    const cancel = () => {
      setEditingKey("");
      setOnEdit(false);
      updateNameItem("Añadir"); // Volver a modo añadir
      formCreate.resetFields();
      setValuesEditInmbuebles((state) => ({
        numeroMatricula: "",
        numeroPredial: "",
        numeroCatastral: "",
        numeroInmueble: "",
        linderos: "",
        area: "",
        direccion: "",
        coeficiente: "",
        avaluo:"",
        id_tipo_inmueble: null,
        proyectoId: null,
        vCancelacionHipoteca:""
      }));
    };

    const delItem = (id) => {
      message.loading("Eliminando...");
      axios
        .delete(`${EXTERNAL_API_PATHS[key]}/${id}`)
        .then(() => {
          setData((prevData) => prevData.filter((item) => item.id !== id));
          message.success("Registro eliminado con éxito");
        })
        .catch(() => {
          message.error("No se pudo eliminar el registro");
        });
    };

    const updateOnEdit = (value) => {
      setOnEdit(value);
    };

    const isEditing = (record) => editingKey === record.id
    const updateNameItem = (value) => setNameItem(value);


  return {
    formEdit,
    formCreate,
    data,
    loading,
    proyectos,
    tiposInmuebles,
    createItem,
    editItem,
    updateOnEdit,
    delItem,
    save,
    onEdit,
    setOnEdit,
    isEditing,
    cancel,
    updateNameItem
  };
};

export default useTableInmueble;
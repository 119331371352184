import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { EXTERNAL_API_PATHS } from "../../../utils/constants";

// Función auxiliar para actualizar valores anidados
const setNestedValue = (obj, path, value) => {
  const keys = path.replace(/\[(\d+)\]/g, ".$1").split(".");
  let current = { ...obj };
  let temp = current;

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      temp[key] = value;
    } else {
      temp[key] = temp[key] || {};
      temp = temp[key];
    }
  });

  return current;
};

const Formulario = ({ initialData = {}, setFormData }) => {
  const [form, setForm] = useState({
    escritura_numero: "",
    fecha_escritura: "",
    certificado_numero: "",
    fecha_certificado: "",
    numero_resolución: "",
    fecha_resolución: "",
    apartamento_numero: "",
    nombre_notario: "",
    nombre_notario_firma: "",
    notario_encargado: "",
    valor_venta: "",
    valor_avaluo: "",
    valor_anticipo: "",
    derechos_notariales: "",
    recaudo_fondo: "",
    recaudo_super: "",
    iva: "",
    pago_retefuente: "",
    linderos: "",
    acredito: "",
    valor_cancelacion_hipoteca: "",
    banco_credito: "",
    valor_credito: "",
    subsidio: "",
    nombre_caja_compensacion: "",
    valor_subsidio_numeros: "",
    fecha_acta_subsidio: "",
    numero_acta_subsidio: "",
    compradores: [],
    inmuebles: [],
    credito: {},
    bono: "",
  });

  const [selectOptions, setSelectOptions] = useState({
    estado_civil: [
      { value: "soltero", label: "Soltero" },
      { value: "casado", label: "Casado" },
      { value: "viudo", label: "Viudo" },
    ],
    tipoDocumento: [
      { value: "cc", label: "Cédula de Ciudadanía" },
      { value: "ce", label: "Cédula de Extranjería" },
      { value: "pasaporte", label: "Pasaporte" },
    ],
    nombre_notario: [
      { value: "HÉCTOR MARIO GARCÉS PADILLA", label: "HÉCTOR MARIO GARCÉS PADILLA" },
      { value: "SANDRA PATRICIA TOBAR PEREZ", label: "SANDRA PATRICIA TOBAR PEREZ" },
      { value: "HELEN ALEJANDRA BROWN PITTO", label: "HELEN ALEJANDRA BROWN PITTO" },
      { value: "SEBASTIÁN CADENA VALENCIA", label: "SEBASTIÁN CADENA VALENCIA" },
    ],
    nombre_notario_firma: [
      { value: "DOCTOR HÉCTOR MARIO GARCÉS PADILLA", label: "DOCTOR HÉCTOR MARIO GARCÉS PADILLA" },
      { value: "DOCTORA SANDRA PATRICIA TOBAR PEREZ", label: "DOCTORA SANDRA PATRICIA TOBAR PEREZ" },
      { value: "DOCTORA HELEN ALEJANDRA BROWN PITTO", label: "DOCTORA HELEN ALEJANDRA BROWN PITTO" },
      { value: "DOCTOR SEBASTIÁN CADENA VALENCIA", label: "DOCTOR SEBASTIÁN CADENA VALENCIA" },
    ],
    notario_encargado: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
    acredito: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
    subsidio: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
    nombre_inmueble: [
      { value: "APARTAMENTO", label: "APARTAMENTO" },
      { value: "PARQUEADERO", label: "PARQUEADERO" },
      { value: "DEPOSITO", label: "DEPOSITO" },
      { value: "CASA", label: "CASA" },
      
    ]
  })
  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      setForm(initialData);
    }
  }, [initialData]);

  const handleChange = (path, value, event) => {
    const updatedForm = setNestedValue(form, path, value);
    setForm(updatedForm);
    setSelectedInmuebles(event.target.value);
    setFormData(updatedForm)
  };

  const [inmuebles, setInmuebles] = useState([]); // Estado para almacenar los datos de inmuebles
  const [selectedInmuebles, setSelectedInmuebles] = useState([]); // Estado para las matriculas seleccionadas
  const [loading, setLoading] = useState(false); // Estado de carga

  const addComprador = () => {
    const newComprador = {
      nombre: "",
      cedula: "",
      ciudad_residente: "",
      estado_civil: "",
      nacionalidad: "",
      tipoDocumento: "",
      telefono: "",
      actividad_economica: "",
      domicilio: "",
      direccion_domicilio: "",
      conyugue: {
        nombre: "",
        identificacion: "",
        nacionalidad: "",
        tipoDocumento: "",
        telefono: "",
        actividad_economica: "",
        domicilio: "",
        direccion_domicilio: "",
      },
      apoderado: {
        nombre: "",
        domicilio: "",
        tipo_documento: "",
        identificacion: "",
      },
    };
    setForm((prev) => ({
      ...prev,
      compradores: [...prev.compradores, newComprador],
    }));
  };

  const addInmueble = () => {
    const newInmueble = {
      numero_matricula_inmobiliaria: "",
      nombre: "",
      numero: "",
      coeficiente: "",
      linderos: "",
      avaluo_catastral_prorrata: "",
    };
    setForm((prev) => ({
      ...prev,
      inmuebles: [...(prev.inmuebles || []), newInmueble],
    }));
  };

  const removeInmueble = (index) => {
    const newInmuebles = form.inmuebles.filter((_, i) => i !== index);
    setForm((prev) => ({ ...prev, inmuebles: newInmuebles }));
  };

  
    // useEffect para obtener los datos de la API
    useEffect(() => {
      const fetchInmuebles = async () => {
        setLoading(true);
        axios
          .get(EXTERNAL_API_PATHS["inmuebles"])
          .then((res) => {
            const formattedData = res.data.map((item) => ({
              id: item.id,
              numeroMatricula: item.matricula,
              numeroPredial: item.numero_predial,
              numeroCatastral: item.numero_catastral,
              linderos: item.linderos,
              area: item.area,
              direccion: item.direccion,
              avaluo: item.avaluo,
              coeficiente: item.coeficiente_ph,    
              proyectoId: item.proyecto?.codigo,  
              proyectoName: item.proyecto?.proyecto    
            }));
            setInmuebles(formattedData);
          })
          .finally(() => setLoading(false));
      };
  
      fetchInmuebles();
    }, []);


  const removeComprador = (index) => {
    const newCompradores = form.compradores.filter((_, i) => i !== index);
    setForm((prev) => ({ ...prev, compradores: newCompradores }));
  };


  return (
    <Box p={1}>
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Datos Básicos
        </Typography>

      {/* Sección Datos básicos */}
        <Grid container spacing={2}>
          {[
            { label: "Número Escritura", path: "escritura_numero", type: "number" },
            { label: "Fecha Escritura", path: "fecha_escritura", type: "date" },
            { label: "Certificado Número", path: "certificado_numero", type: "number" },
            { label: "Fecha Certificado", path: "fecha_certificado", type: "date" },
            { label: "Número Resolución", path: "numero_resolución", type: "number" },
            { label: "Fecha Resolución", path: "fecha_resolución", type: "date" },
            { label: "Nombre Notario", path: "nombre_notario", type: "select", options: selectOptions.nombre_notario },
            { label: "Nombre Notario Firma", path: "nombre_notario_firma", type: "select", options: selectOptions.nombre_notario_firma },
            { label: "Notario Encargado", path: "notario_encargado", type: "select", options: selectOptions.notario_encargado },
          ].map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {field.type === "select" ? (
                <TextField
                  select
                  label={field.label}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  label={field.label}
                  type={field.type}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                  InputLabelProps={field.type === "date" ? { shrink: true } : {}}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Valores
        </Typography>
      {/* Sección Valores */}
        <Grid container spacing={2}>
          {[
            { label: "Valor Venta", path: "valor_venta", type: "number" },
            { label: "Valor Avalúo", path: "valor_avaluo", type: "number" },
            { label: "Valor Anticipo", path: "valor_anticipo", type: "number" },
            { label: "Derechos Notariales", path: "derechos_notariales", type: "number" },
            { label: "Recaudo Fondo", path: "recaudo_fondo", type: "number" },
            { label: "Recaudo Super", path: "recaudo_super", type: "number" },
            { label: "IVA", path: "iva", type: "number" },
            { label: "Pago Retefuente", path: "pago_retefuente", type: "number" },
            { label: "Valor Cancelación Hipoteca",path: "valor_cancelacion_hipoteca",type: "number"},
          ].map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {field.type === "select" ? (
                <TextField
                  select
                  label={field.label}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                >
                </TextField>
              ) : (
                <TextField
                  label={field.label}
                  type={field.type}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Sección Forma de Pago */}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Forma de Pago
        </Typography>
        <Grid container spacing={2}>
          {[
            { label: "Acredito", path: "acredito", type: "select", options: selectOptions.acredito },
            { label: "Subsidio", path: "subsidio", type: "select", options: selectOptions.subsidio },
            { label: "Nombre Caja Compensación",path: "nombre_caja_compensacion",type: "text",},
            { label: "Valor Subsidio Números",path: "valor_subsidio_numeros",type: "number",},
            { label: "Fecha Acta Subsidio", path: "fecha_acta_subsidio", type: "date" },
            { label: "Número Acta Subsidio", path: "numero_acta_subsidio", type: "number" },
          ].map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {field.type === "select" ? (
                <TextField
                  select
                  label={field.label}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  label={field.label}
                  type={field.type}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                  InputLabelProps={field.type === "date" ? { shrink: true } : {}}
                />
              )}
            </Grid>
          ))}
        </Grid>
      {/* Sección Credito */}
      {form.acredito=='si'? <>
        <Box mt={4}>
        <Typography variant="h5">Crédito</Typography>
          <Grid container spacing={2}>
            {[
              { label: "Banco Crédito", path: "credito.banco_credito", type: "text" },
              { label: "Valor Crédito", path: "credito.valor_credito", type: "number" },
              { label: "Número Carta", path: "credito.numero_carta", type: "number" },
              { label: "Fecha Carta", path: "credito.fecha_carta", type: "date" },
              { label: "UVR", path: "credito.uvr", type: "number" },
              { label: "Plazo Crédito", path: "credito.plazo_credito", type: "number" },
              { label: "Valor Mensual", path: "credito.valor_mensual", type: "number" },
            ].map((field, index) => (
              <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    label={field.label}
                    type={field.type}
                    value={form?.credito?.[field.path.split(".")[1]] || ""}
                    onChange={(e) => handleChange(field.path, e.target.value)}
                    fullWidth
                    InputLabelProps={field.type === "date" ? { shrink: true } : {}}
                  />
              </Grid>
            ))}
          </Grid>
          </Box>
        </>:<></>}
      </Box>

      {/* Sección Inmuebles*/}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Box mt={4}>
          <Typography variant="h5">Inmuebles</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={addInmueble}
            style={{ marginBottom: "20px" }}
          >
            Agregar Inmueble
          </Button>
          {form.inmuebles?.map((inmueble, index) => (
            <Box key={index} mb={3} p={2} style={{ border: "1px solid #ccc" }}>
              <Typography variant="h6">Inmueble {index + 1}</Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Número Matrícula Inmobiliaria", path: "numero_matricula_inmobiliaria", type: "text" },
                  { label: "Nombre", path: "nombre", type: "select", options: selectOptions.nombre_inmueble },
                  { label: "Número", path: "numero", type: "text" },
                  { label: "Coeficiente", path: "coeficiente", type: "number" },
                  { label: "Linderos", path: "linderos", type: "text" },
                  { label: "Avalúo Catastral Prorrata", path: "avaluo_catastral_prorrata", type: "number" },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={inmueble?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`inmuebles[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={inmueble?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`inmuebles[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeInmueble(index)}
                style={{ marginTop: "20px" }}
              >
                <DeleteIcon />
                Eliminar Inmueble
              </Button>
            </Box>
          ))}
        </Box>

        {/* NUEVA SECCIÓN DE INMUEBLES */}
        <Box mt={4}>
          <Typography variant="h5">Inmuebles</Typography>

          <Box mb={3} p={2} style={{ border: "1px solid #ccc", maxWidth: "800px" }}>
    <Typography variant="h8" gutterBottom>
      Seleccionar inmueble(s)
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Select
            style={{ width: "100%" }}
            multiple
            value={selectedInmuebles}
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const inmueble = inmuebles.find((item) => item.id === id);
                  return inmueble ? inmueble.matricula : id;
                })
                .join(", ")
            }
          >
            {inmuebles.map((inmueble) => (
              <MenuItem key={inmueble.id} value={inmueble.id}>
                {inmueble.matricula}
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
          </Box>
        </Box>
      </Box>
      {/* Sección Compradores */}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Box mt={4}>
          <Typography variant="h5">Compradores</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={addComprador}
            style={{ marginBottom: "20px" }}
          >
            Agregar Comprador
          </Button>
          {form.compradores?.map((comprador, index) => (
            <Box key={index} mb={3} p={2} style={{ border: "1px solid #ccc" }}>
              <Typography variant="h6">Comprador {index + 1}</Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Nombre", path: "nombre", type: "text" },
                  { label: "Cédula", path: "cedula", type: "number" },
                  { label: "Ciudad Residente", path: "ciudad_residente", type: "text" },
                  { label: "Estado Civil", path: "estado_civil", type: "select", options: selectOptions.estado_civil},
                  { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                  { label: "Tipo Documento", path: "tipoDocumento", type: "select", options: selectOptions.tipoDocumento},
                  { label: "Teléfono", path: "telefono", type: "number" },
                  { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                  { label: "Domicilio", path: "domicilio", type: "text" },
                  { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={comprador?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`compradores[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      >
                      {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={comprador?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`compradores[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}

                {/* Sección Conyugue */}
                {comprador?.estado_civil === "casado" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Cónyuge</Typography>
                    </Grid>

                    {[
                      { label: "Nombre", path: "nombre", type: "text" },
                      { label: "Identificación", path: "identificacion", type: "number" },
                      { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                      { label: "Tipo Documento", path: "tipoDocumento", type: "select" },
                      { label: "Teléfono", path: "telefono", type: "number" },
                      { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                      { label: "Domicilio", path: "domicilio", type: "text" },
                      { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                    ].map((field, i) => (
                      <Grid item xs={6} key={i}>
                        {field.type === "select" ? (
                          <TextField
                            select
                            label={field.label}
                            value={comprador.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(index, `conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          >
                            <MenuItem value="">Seleccione...</MenuItem>
                            <MenuItem value="opcion1">Opción 1</MenuItem>
                            <MenuItem value="opcion2">Opción 2</MenuItem>
                          </TextField>
                        ) : (
                          <TextField
                            label={field.label}
                            type={field.type}
                            value={comprador.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(index, `conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}

                {/* Sección Apoderado */}
                <Grid item xs={12}>
                  <Typography variant="h6">Apoderado</Typography>
                </Grid>
                {[
                  { label: "Nombre", path: "nombre", type: "text" },
                  { label: "Domicilio", path: "domicilio", type: "text" },
                  { label: "Tipo Documento", path: "tipo_documento", type: "select", options: selectOptions.tipoDocumento },
                  { label: "Identificación", path: "identificacion", type: "number" },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={comprador.apoderado?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`compradores[${index}].apoderado.${field.path}`, e.target.value)
                        }
                        fullWidth
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={comprador.apoderado?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`compradores[${index}].apoderado.${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeComprador(index)}
                style={{ marginTop: "20px" }}
              >
                <DeleteIcon />
                Eliminar Comprador
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Formulario;
